@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,700');
$primary: #C64C54;
/* MAIN COLOR */

$yellow:#FABF50;
$secondary: #79B6B8;
/* SECONDARY COLOR */

$blk: #333;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop
html,
body {
    height: 100%;
    font-family: 'Oswald', sans-serif;
}

html {
    @include fluid-type($min_width,
    $max_width,
    $min_font,
    $max_font);
}

p {
    font-size: .9rem;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.navbar-default {
    background-color: $secondary;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $wht;
            font-weight: bolder;
            font-size: 1.2em;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 8px;
                display: inline-block;
                text-align: center;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $wht;
                outline: 0;
            }
            &:hover {
                background: $yellow;
                color: lighten($wht, 20%);
                border-top: 3px solid lighten($wht, 32%);
                border-radius: 10px;
                font-weight: bold;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $wht;
                    outline: 0;
                }
            }
        }
        .en {
            background-color: $yellow;
            border-radius: 50px;
            margin-right: 5px;
            margin-left: 5px;
            &:hover {
                background-color: $primary;
                border-radius: 50px;
            }
        }
        .es {
            background-color: $yellow;
            border-radius: 50px;
            @media(max-width: 767px) {
                margin-left: 5px;
                margin-top: 5px;
            }
            &:hover {
                background-color: $primary;
                border-radius: 50px;
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: 50px;
    background: $yellow;
    color: $wht;
    padding: 1em 2em;
    font-size: 1.1em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 1em 0em;
    transition: 0.7s ease all;
    text-decoration: none;
    &active,
    &:visited,
    &:focus {
        background: $yellow;
        color: $wht;
        text-decoration: none;
    }
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            text-decoration: none;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
            font-size: 25px;
        }
    }
    a.btn,
    a.btn-default,
    button {
        @include btn;
        margin: 0;
        display: block;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
} // section start here
section {
    p {
        font-weight: 100;
    }
    .imgbox {
        padding: 7px;
        border: 5px solid $secondary;
        background-color: $yellow;
        box-shadow: lighten($blk, 0%) 0 0 15px 0px;
    }
}

.delay-1 {
    animation-delay: .25s;
}

.delay-2 {
    animation-delay: .5s;
}

.delay-3 {
    animation-delay: .75s;
}

.delay-4 {
    animation-delay: 1s;
}

.box {
    background-color: rgba($primary, .8);
    padding: 200px 0;
    @media (max-width: 1024px) {
        padding: 250px 0;
    }
    @media (max-width: 767px) {
        padding: 150px 0;
    }
    @media (max-width: 600px) {
        padding: 50px 0;
    }
}

.bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    @media(max-width: 1024px) {
        background-attachment: scroll;
    }
}

.pd {
    padding: 100px 0;
    @media(max-width: 1024px) {
        padding: 70px 0;
    }
    @media(max-width: 767px) {
        padding: 50px 0;
    }
}

.section-a {
    @extend .bg;
    background-image: url("../img/bg1.jpg");
    color: $wht;
    h1 {
        font-weight: bold;
        text-transform: uppercase
    }
}

.section-b {
    @extend .pd;
    background-color: lighten($blk, 5%);
    color: $wht;
    h3 {
        font-weight: bold
    }
    span {
        font-weight: bold;
        color: $yellow
    }
}

.section-c {
    @extend .pd;
    h3 {
        font-weight: bold;
        color: $primary;
    }
    span {
        font-weight: bold;
        color: $primary;
    }
}

.section-d {
    @extend .pd;
    background-color: darken($primary, 10%);
    color: $wht;
    .space {
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .space2 {
        margin-bottom: 50px;
    }
    .col-md-4 {
        h3 {
            font-size: 1.8em;
            font-weight: bold;
            color: $yellow;
        }
    }
    .top-serv {
        margin-block-end: 40px;
        h3 {
            font-weight: bold;
        }
    }
    span {
        font-weight: bold
    }
    .imgbox {
        @media(max-width: 991px) {
            max-width: 250px;
        }
    }
}

.section-e {
    @extend .pd;
    background-color: lighten($blk, 5%);
    color: $wht;
    h3 {
        font-weight: bold
    }
    a {
        text-decoration: none;
        color: $wht;
        padding: 10px 30px;
    }
    i {
        font-size: 1.5em;
        margin-top: 20px;
        margin-bottom: 15px;
        color: $wht;
    }
    span {
        font-weight: bold;
        color: $yellow;
    }
    h4 {
        @media(max-width: 667px) {
            font-size: 1em;
        }
        @media(max-width: 380px) {
            font-size: .8em;
        }
    }
} // section ends here 
/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-height: 100px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-height: 80px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 767px) {
    .navbar-toggle {
        margin-top: 22px
    }
}

.top-pad {
    padding: 3em;
    background: white;
    @media (max-width: 990px) {
        padding: 1em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 1rem;
    }
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}